/* You can add global styles to this file, and also import other style files */
/* @import "~bootstrap/dist/css/bootstrap.css";*/

/* .btn {
    height: 3.5rem;
}

.btn-primary {
    background-color: #06a7de !important
}
.btn-outline-primary:hover {
    background-color: #06a7de !important;
}

.btn-outline-primary {
    border-color: #06a7de !important;
}

.btn-outline-primary:not(:disabled):not(.disabled).active, .btn-outline-primary:not(:disabled):not(.disabled):active, .show>.btn-outline-primary.dropdown-toggle {
    background-color: #06a7de !important
}

.modal-dialog {
    margin: 0;
    top: 40%;
    position: absolute;
    left: 0%;
    transform: translate(-50%,-50%);
  }
  body label{
    font-size: .9rem;
  } */

/* ----------------------------------------------- Bootstrap 3 - CSS ----------------------------- */

/* --------------------------------------- NAVBAR ------------------------------------------------ */
.navbar {
  margin-bottom: 0px !important;
  padding: 0px;
}

.navbar .dropdown-toggle {
  background-color: #fff;
  float: right;
}

.navbar .dropdown-toggle .glyphicon {
  padding-left: 3px;
}

.navbar ul .dropdown-item {
  display: block;
  width: 90%;
  background-color: #fff;
  box-shadow: none;
  border: 0px;
  padding: 5px 10px 5px 15px;
  ;
  font-size: 15px;
}

.navbar ul .dropdown-item img {
  float: left;
  margin-top: 2px;
}

.dropdown-menu-right {
  top: 40px;
}

/* ------------------------------------------ NAVBAR ---------------------------------- */

/* ------------------------------------------ LANDING PAGE ---------------------------- */

app-landing .timeline>li {
  padding-top: 16px;
  font-size: 14px;
  display: inline-block;
  list-style-type: none;
  padding-right: 20px;
  vertical-align: middle;
  width: 100%;
}

app-landing .timeline-pic {
  width: 36px;
  display: inline-block;
}

app-landing .timeline div {
  display: inline-block;
  width: 80%;
  vertical-align: middle;
  padding-left: 15px;
}

app-landing .almost-bottom {
  margin-top: 40px;
}

app-landing .filler {
  margin-top: 16px;
}

app-landing .sub-heading {
  font-size: 16px;
  line-height: 1.5;
}

/* ------------------------------------------ LANDING PAGE ---------------------------- */


/* ------------------------------------------ APP ID SELECT ----------------------------------- */

app-id-select .padding16 {
  padding: 16px;
}

.text-center {
    text-align: center;
}

.m-t-15 {
    margin-top: 15px !important;
}

.padding-15{
    padding-left: 15px;
    padding-right: 15px;
}

/* ------------------------------------------ APP ID SELECT ----------------------------------- */

/* ------------------------------------------ APP CONFIRMATION ----------------------------------- */



/* ------------------------------------------ APP CONFIRMATION ----------------------------------- */

/* ------------------------------------------ BUTTON ----------------------------------- */

.button {
  height: 100%;
  font-size: 12px;
  padding: 15px 10px !important;
  cursor: pointer;
  margin-bottom: 24px !important;
  text-decoration: none;
  text-transform: uppercase;
  border-radius: 5px;
  background-color: #06a7de !important;
  border-color: #007bff;
  color: #fff;
}

.grow {
  display: inline-block;
  -webkit-transition-duration: 0.5s;
  transition-duration: 0.5s;
  -webkit-transition-property: -webkit-transform;
  transition-property: transform;
  -webkit-transform: translateZ(0);
  transform: translateZ(0);
}

.grow:hover {
  -webkit-transform: scale(1.05);
  -ms-transform: scale(1.05);
  transform: scale(1.05);
  text-decoration: none;
}

.btn-align {
  margin: 0 auto;
  display: block !important;
  width: 200px;
  min-width: 200px;
}

.btn-select {
  margin: 0 auto;
  display: block !important;
  width: 290px;
  min-width: 290px;
  border-color: #06a7de !important;
  background-color: transparent !important;
  color: #007bff;
}

.link {
  text-align: center;
}

#optout-modal .modal-footer .row>div {
  display: inline-block;
  padding-right: 15px;
}

#abortmodal .modal-footer .row>div {
  display: inline-block;
  padding-right: 15px;
}

.width-100 {
    width: 100px;
}

.mar-r-5 {
    margin-right: 5px;
}

/* ------------------------------------------ BUTTON ----------------------------------- */

/* --------- Icons CSS Start  -------- */
.rightIdIcons{
  position: relative;
  font-size: 48px;
  top: 15px;
  display: inline-block;
  width:50px;
}
.rightIdConfirmIcons{
  position: relative;
  font-size: 30px;
  top: 12px;
  display: inline-block;
  width:50px;
}

/* --------- Icons CSS End  -------- */


/*-------------------------------- IcoMoon icons css Start ------------------------------*/
@font-face {
  font-family: 'icomoon';
  src:  url('./assets/fonts/icomoon.eot');
  src:  url('./assets/fonts/icomoon.eot#iefix') format('embedded-opentype'),
    url('./assets/fonts/icomoon.ttf') format('truetype'),
    url('./assets/fonts/icomoon.woff') format('woff'),
    url('./assets/fonts/icomoon.svg#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check-mark:before {
  content: "\e900";
}
.icon-choose-document:before {
  content: "\e901";
}
.icon-close:before {
  content: "\e902";
  color: #dd2e44;
}
.icon-license:before {
  content: "\e903";
}
.icon-loading:before {
  content: "\e904";
  color: #1d3f72;
}
.icon-phone-camera:before {
  content: "\e905";
}
.icon-selfie:before {
  content: "\e906";
}
.icon-verify-sign:before {
  content: "\e907";
}

/*-------------------------------- IcoMoon icons css End ------------------------------*/

/*  Mitek Camera styles START */
/* mitek icons */

.loader-wrap {
  position: fixed;
  width: 120px;
  height: 120px;
  top: 50%;
  left: 50%;
  margin-left: -60px;
  margin-top: -60px;
  display: block;
}

.loader {
  border: 16px solid #f3f3f3;
  /* Light grey */
  border-top: 16px solid #3498db;
  border-bottom: 16px solid #3498db;
  /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/*  Mitek Camera styles END */

.btn.focus, .btn:focus, .btn:hover {
  color: #007bff !important;
}

.modal-footer {
  text-align: center !important;
}

#aidBtnRotateCamera {
  visibility: hidden !important;
}

#aidFooterButtons {
  flex-direction: row-reverse !important;
}

#aidFooterButtons #aidBtnCancelCapture #aidCancelSpan {
  flex-direction: row-reverse !important;
}

@media (orientation: landscape) {
  #aidHeader #aidAutoMode {
    align-items: flex-start !important;
  }
}

.m-b-20 {
  margin-bottom: 20px;
}

.video_link span {
  font-size : 16px!important;
  font-weight: 600;
}

div#showVideoModal .modal-dialog {
  top: 0% !important;
}

.embeddedServiceHelpButton .helpButton {
  max-width: 160px !important;
}

.embeddedServiceHelpButton .helpButton .uiButton{
  background-color: #0071CE !important;
  font-family: "Arial", sans-serif !important;
  color: #fff !important;
}

.embeddedServiceHelpButton .helpButton .uiButton:focus {
  outline: 1px solid #0071CE !important;
}

.embeddedServiceHelpButton .embeddedServiceIcon:before {
  font-family: "embeddedserviceiconfont" !important;  
}

.navbar-brand {
  padding: 0px !important;
}

.marTop10 {
  margin-top: 10px!important;
}

/* .logo {
  width: 145px !important;
  height: 65px !important;
  background: url(./assets/img/rightid_logo.png) no-repeat !important;
  background-size: 145px 65px !important;
} */